export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const SET_BUNDLES_DEFAULT = 'SET_BUNDLES_DEFAULT';
export const SET_BUNDLES_OFFER = 'SET_BUNDLES_OFFER';
export const SET_BUNDLES_ALL = 'SET_BUNDLES_ALL';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const SET_API_CONTACT_MAIL = 'SET_API_CONTACT_MAIL';
export const SET_NAME = 'SET_NAME';
export const SET_SILAE_CLIENT_ID = 'SET_SILAE_CLIENT_ID';
export const SET_BILLING_CODE = 'SET_BILLING_CODE';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_OFFER_TYPE = 'SET_OFFER_TYPE';
export const SET_PLATFORM = 'SET_PLATFORM';
export const SET_ENTITY = 'SET_ENTITE';
export const SET_DOMAIN = 'SET_DOMAIN';
export const TOGGLE_BUNDLE = 'TOGGLE_BUNDLE';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_LIMIT = 'SET_LIMIT';
