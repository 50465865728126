/* ============
 * Account mutations
 * ============
 *
 * The mutations available for the account module.
 */

import Vue from 'vue';
import * as types from './types';

export default {
  [types.SET_ACCOUNT](state, payload) {
    state.account = payload;
  },

  [types.SET_SUBSCRIPTIONS](state, payload) {
    state.subscriptions = payload;
  },

  [types.SET_SUBSCRIPTION](state, payload) {
    state.subscription = payload;
  },

  [types.SET_BUNDLES_DEFAULT](state, payload) {
    state.bundles.default = payload;
  },

  [types.SET_BUNDLES_OFFER](state, payload) {
    state.bundles.offer = payload;
  },

  [types.SET_BUNDLES_ALL](state, payload) {
    state.bundles.all = payload;
  },

  [types.UPDATE_SUBSCRIPTION](state, payload) {
    state.subscriptions[state.subscriptions.findIndex((el) => el.id === payload.id)] = payload;
  },

  [types.SET_API_CONTACT_MAIL](state, mail) {
    Vue.set(state.account, 'apiContactMail', mail);
  },

  [types.SET_OFFER_TYPE](state, offer) {
    Vue.set(state.account, 'offerType', offer);
  },

  [types.SET_NAME](state, name) {
    state.account.name = name;
  },

  [types.SET_SILAE_CLIENT_ID](state, id) {
    Vue.set(state.account, 'silaeClientId', id);
  },

  [types.SET_BILLING_CODE](state, code) {
    Vue.set(state.account, 'billingCode', code);
  },

  [types.SET_COMPANY](state, id) {
    state.account.companyId = id;
  },

  [types.SET_PLATFORM](state, platform) {
    Vue.set(state.account, 'platform', platform);
  },

  [types.SET_ENTITY](state, entity) {
    Vue.set(state.account, 'entity', entity);
  },

  [types.SET_DOMAIN](state, domain) {
    Vue.set(state.account, 'domain', domain);
  },

  [types.TOGGLE_BUNDLE](state, bundle) {
    if (bundle.enabled) {
      state.bundles.default.splice(
        state.bundles.default.indexOf({ id: bundle.id, name: bundle.name }),
        1,
      );
    } else state.bundles.default.push({ id: bundle.id, name: bundle.name });
  },

  [types.SET_CURRENT_PAGE](state, value) {
    state.subscriptions.pagination.currentPage = value;
  },

  [types.SET_LIMIT](state, value) {
    state.subscriptions.pagination.limit = value;
  },
};
